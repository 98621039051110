<template>
  <div>
    <list-providers />
  </div>
</template>

<script>
import ListProviders from "@/@core/components/shared/admin/users/ListProviders.vue"

export default {
  components: {
    ListProviders
  },
  methods: {}
}
</script>

