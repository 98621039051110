<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        ref="users_table_ref"
        :records="users" 
        :columns="tableColumns"
        :total-records="totalRecords"
        :search-filter.sync="searchFilter"
        :current-page-number.sync="currentPage"
        :show-export-button="false"
        :show-import-button="false"
      >
        <template #cell(logo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getValueFromSource(data, 'item.logo')"
                :text="sentenceCase(avatarText(`${data.item.name}`))"
                :variant="`light-primary`"
                :to="{ name: 'admin-user-single', params: { id: data.item._id } }"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data, 'item.name')) }}
            </b-link>
            <span class="text-muted">{{ data.item.contact_person }}</span><br />
            <span class="text-muted">{{ data.item.contact_phone }}</span><br />
            <span class="text-muted">{{ data.item.contact_email }}</span>
          </b-media>
        </template>

        <template #cell(meta)="data">
          <b-badge
            pill
            :variant="getValueFromSource(data, 'item.meta.admin_approved') ? 'light-success' : `light-warning`"
            class="text-capitalize"
          >
            {{ getValueFromSource(data, 'item.meta.admin_approved') ? 'Approved' : 'Pending' }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            
            <b-dropdown-item v-if="!getValueFromSource(data, 'item.meta.admin_approved')" @click="onActionRequest(data.item._id, 'Approve')">
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Approve</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="getValueFromSource(data, 'item.meta.admin_approved')" @click="onActionRequest(data.item._id, 'UnApprove')">
              <feather-icon icon="CornerLeftDownIcon" />
              <span class="align-middle ml-50">UnApprove</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BInputGroup, BInputGroupAppend, BFormInput
} from 'bootstrap-vue'
import { get, debounce } from "lodash"

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const watchHandler = {
  handler(){
    this.debouncedFetchUsersData()
  }, 
  immediate: false
}
export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BInputGroup, 
    BInputGroupAppend, 
    BFormInput,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    showAddUserButton: {
      type: Boolean,
      default: true
    },
    clientGroupId: {
      type: String,
      default: ""
    }
  },
  data(){
    return {
      loading: false,
      showFilter: false,
      showListTable: false,
      statusFilter: null,
      userTypeFilter: 'provider',
      searchFilter: "",
      users: [],
      importType: "",
      tableColumns: [
        { key: 'logo', stickyColumn: true, sortable: false, label: '' },
        { key: 'name', sortable: false, label: 'Name' },
        { key: 'tin', sortable: false, label: 'TIN', formatter: val => val || 'N/A', },
        { key: 'country', sortable: false },
        { key: 'region', sortable: false },
        { key: 'city', sortable: false },
        { key: 'meta', sortable: false, label: 'Status' },
        { key: 'actions' },
      ],
      debouncedFetchUsersData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchUsersData();
      }, 
      immediate: false
    },
    currentPage: watchHandler,
    statusFilter: watchHandler,
    searchFilter: watchHandler,
    userTypeFilter: watchHandler,
  },
  created(){
    this.debouncedFetchUsersData = debounce(this.fetchAllData, 500);

    const { 
      page = 1, 
      user_type,
      search = "", 
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.userTypeFilter = user_type;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchFilter,
          user_type: this.userTypeFilter
        }
        if (this.clientGroupId) {
          query.client_group_id = this.clientGroupId
        }
        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().adminService.fetchProviders(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.users = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onActionRequest(id, action){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Update?',
          text: `Do you want to ${action} this company?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const status = action === 'Approve'
        await this.useJwt().adminService.actionProvider(id, status);
        await this.fetchAllData();
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    onRowClicked(rowItem){
      this.$router.push({ name: 'admin-user-single', params: { id: rowItem._id } })
      .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>
